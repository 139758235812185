<template>
  <div class="auth-page-wrapper pt-5">
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="shape">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120"
        >
          <path
            d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8
            1440,40L1440 140L0 140z"
          ></path>
        </svg>
      </div>
    </div>

    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div style="font-weight: 600; font-size: 30px">CEM</div>
            </div>
          </div>
        </div>
        <div class="login-container position-relative">
          <LoadingPage v-if="loading"></LoadingPage>
          <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
              <div class="card mt-4">
                <div class="card-body p-4">
                  <div class="text-center mt-2">
                    <h5 class="text-primary">{{ $t("t-wellcome-back") }}</h5>
                    <p class="text-muted">
                      Bạn cần đăng nhập để được sử dụng các chức năng của hệ
                      thống
                    </p>
                  </div>
                  <div class="p-2 mt-4">
                    <el-form
                      ref="ruleFormRef"
                      :model="dataForm.value"
                      status-icon
                      :rules="rulesForm"
                      label-width="150px"
                      label-position="top"
                      class="form-login"
                      @submit.prevent
                    >
                      <el-form-item label="Tài khoản" prop="account">
                        <el-input
                          type="text"
                          autocomplete="off"
                          v-model="dataForm.value.account"
                          tabIndex="1"
                          autofocus
                          v-on:keyup.enter="nextFocus"
                        />
                      </el-form-item>
                      <el-form-item label="Mật khẩu" prop="password">
                        <el-input
                          type="password"
                          autocomplete="off"
                          v-model="dataForm.value.password"
                          show-password
                          tabIndex="2"
                          id="inputPass"
                        />
                      </el-form-item>
                      <el-form-item 
                        v-if="showTypeLogin"
                        prop="typeLogin" 
                        class="type-login"
                      >
                        <el-radio-group
                          v-model="dataForm.value.typeLogin"
                          class="ml-4"
                        >
                          <el-radio label="probe" size="large">PROBE</el-radio>
                          <el-radio label="bras" size="large">BRAS</el-radio>
                        </el-radio-group>
                      </el-form-item>

                      <el-form-item>
                        <el-button
                          type="button"
                          class="btn btn-success btn-load w-100"
                          @click="loginForm(ruleFormRef)"
                          tabindex="3"
                          native-type="submit"
                        >
                          {{ $t("t-login") }}
                        </el-button>
                      </el-form-item>
                      <div class="text-center">
                        Quên mật khẩu?
                        <a
                          @click="goForgotPassword()"
                          style="color: #409eff; cursor: pointer"
                          >Lấy lại mật khẩu</a
                        >
                      </div>
                    </el-form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { FormInstance } from "element-plus";
import { useRouter } from "vue-router";
import toastr from "toastr";
import LoadingPage from "../../components/loading.vue";
import mushroom from "cem-primary-api";
const ruleFormRef = ref(FormInstance);
const router = useRouter();

let loading = ref(false);
const showTypeLogin = ref(false);
// dữ liệu form
let dataForm = reactive({
  value: {
    account: "",
    password: "",
    typeLogin: "",
  },
});
// validate form
const rulesForm = reactive({
  account: [
    { trigger: "blur", required: true, message: "Vui lòng nhập tài khoản" },
  ],
  password: [
    {
      trigger: "blur",
      required: true,
      message: "Vui lòng nhập mật khẩu",
    },
  ],
  typeLogin: [
    {
      trigger: "blur",
      required: true,
      message: "Vui lòng chọn PROBE hoặc BRAS",
    },
  ],
});

// function
const loginForm = async (formEl) => {
  loading.value = true;
  formEl.validate(async (valid) => {
    if (valid) {
      try {
        const response = await mushroom.$auth.loginAsync(
          dataForm.value.account,
          dataForm.value.password,
          true
        );
        if (response.result.token) {
          router.push({ name: "default" });
          if(showTypeLogin.value) {
            localStorage.setItem("type_login", dataForm.value.typeLogin);
          } else localStorage.setItem("type_login", 'probe');
          setTimeout(() => {
            loading.value = false;
          }, 2000);
        }
      } catch (e) {
        if (e.code === 4) {
          toastr.error("Gặp sự cố kết nối mạng Internet");
        } else
          toastr.error(
            "Đăng nhập thất bại. Vui lòng nhập lại tài khoản và mật khẩu"
          );
        loading.value = false;
      }
    } else loading.value = false;
  });
};

const goForgotPassword = () => {
  let urlLogin = location.origin + "/forgot-password";
  window.location.href = urlLogin;
};

const nextFocus = () => {
  document.getElementById("inputPass").focus();
};

onMounted(() => {
  if(window.location.host.includes('localhost') || window.location.host.includes('siten.vn')) showTypeLogin.value = true;
  else showTypeLogin.value = false;
});

</script>
<style scoped lang="scss">
::v-deep.form-login {
  .el-form-item__error {
    top: 35px;
  }
  .el-form-item {
    margin-bottom: 25px;
  }
}
::v-deep.type-login {
  .el-radio.el-radio--large {
    height: 30px;
  }
  .el-form-item__error {
    top: 26px;
  }
  label {
    margin-bottom: 0;
  }
}
</style>
